@import "src/assets/css/_variables.scss";

.ObservationPage {
  .comments,
  .behaviour,
  .location {
    white-space: pre-line;
  }
  h2 {
    font-size: $h3-font-size;
  }
}
