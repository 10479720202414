@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_mixins.scss";

// Custom

$orange-dark: #df5206;
$green-dark: #6a7029;
$brown-dull: #dfd4ba;
$brown-faded: #e6dfcc;

$font-family-serif: "Playfair Display", Georgia, "Times New Roman", Times, serif;

$font-size-md: 0.925rem;

$footer-outer: lighten($brown-faded, 5%);
$footer-inner: $brown-faded;
$footer-border: darken($brown-faded, 5%);

// Bootstrap Variables

$body-bg: #f5f5f5;

$primary: $orange-dark;
$secondary: $green-dark;
$dark: #231f20;
$light: #e9ecef;

$font-family-sans-serif: "Open Sans", Roboto, Arial, sans-serif;
$headings-font-family: $font-family-serif;

$spacer: 1rem !default;

$navbar-padding-y: $spacer;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1080px,
);

$list-group-item-padding-x: 1rem;
$list-group-item-padding-y: 0.5rem;

$card-border-radius: 0;

@import "~bootstrap/scss/_variables.scss";
