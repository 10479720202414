@import "src/assets/css/_variables.scss";

.BirdCard {
  font-size: $font-size-md;

  .card-title {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    a {
      color: $green-dark;
    }
  }
}
