@import "src/assets/css/_variables.scss";

.Posts {
  .Post {
    h3,
    h4 {
      font-family: $font-family-base;
    }

    h3 {
      font-weight: 600;
    }
    h4 {
      font-style: italic;
    }
  }
}
