@import "src/assets/css/_variables.scss";

.Sponsors {
  background-color: lighten($brown-faded, 10%);

  ul {
    padding-left: 0;
    list-style: none;

    li {
      padding-bottom: map-get($spacers, 1);
      a {
        color: $body-color;

        &:hover {
          color: $body-color;
        }
      }
    }
  }

  h3 {
    font-size: $font-size-base;
    font-weight: 600;
    font-family: $font-family-base;
    text-transform: uppercase;
  }
}
