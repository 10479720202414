@import "src/assets/css/_variables.scss";

nav {
  .navbar-brand img {
    height: 28px;

    @include media-breakpoint-up(sm) {
      height: 32px;
    }
  }
}
