@import "src/assets/css/_variables.scss";

.BannerButtons {
  background-color: $green-dark;

  .container {
    padding-top: $spacer;
    padding-bottom: $spacer;

    .banner-button .btn {
      width: 100%;
      font-size: 1.1rem;
    }

    @include media-breakpoint-down(md) {
      .banner-button .btn {
        font-size: $font-size-base;
      }
    }
  }
}
